import React, { useEffect, useState } from "react";
// import data from "../data/data.json";
import conditions from "../data/conditions.json";
function FormTwo({ data, setData }) {
  const selectedConstruction = sessionStorage.getItem("selectedConstruction");

  const [editable, setEditable] = useState(false);

  // const [data, setData] = useState([]);

  // useEffect(() => {
  //   fetch('/data.json')
  //     .then(response => response.json())
  //     .then(jsonData => setData(jsonData))
  //     .catch(error => console.error('Error loading data:', error));
  // }, []);

  // const handleCheckboxChange = (itemId, propertyName) => {
  //   const updatedData = data.map(item => {
  //     if (item.id === itemId) {
  //       return { ...item, [propertyName]: !item[propertyName] };
  //     }
  //     return item;
  //   });
  //   setData(updatedData);
  // };

  const handleCheckboxChange = (itemId, propertyName) => {
    console.log(itemId, "-------------");
    // if (!itemId.startsWith("C")) return 0;
    // console.log(itemId, "-------------");

    setData((prev) => {
      const updatedData = prev.map((section) => {
        const updatedSubSections = section.sub.map((subSection) => {
          const updatedItems = subSection.data.map((item) => {
            if (item.id === itemId) {
              var obj = { ...item, [propertyName]: !item[propertyName] };
              [
                "COMPLIES",
                "DOES NOT COMPLY",
                "DESIGN DETAIL",
                "DESIGN CERTIFICATION",
                "NOT APPLICABLE",
                "PERFOMANCE SOLUTION",
                "CAPABLE OF COMPLIANCE",
              ].forEach((e) => {
                if (e !== propertyName) {
                  obj[e] = false;
                }
              });
              return obj;
            }
            return item;
          });

          return { ...subSection, data: updatedItems };
        });

        return { ...section, sub: updatedSubSections };
      });
      return updatedData;
    });
  };

  return (
    <>
      <form className="p-4 pt-2">
        <div>
          <div className="bg-light rounded-3 d-inline-block mb-4 p-3">
            <label className="">
              <strong>Type of Construction :</strong>{" "}
            </label>{" "}
            <input type="text" disabled value={selectedConstruction} />
          </div>
        </div>
        {data.map((selected) => {
          return (
            <>
              <h4 className="multipleForm-heading bg-primaryLight p-2">
                {selected?.section}
              </h4>
              {selected?.sub?.map((obj) => {
                return (
                  <>
                    <h5 className="multipleForm-subHeading ">{obj.name}</h5>

                    <table className="table table-hover multipleForm-table">
                      <thead>
                        <tr>
                          <th
                            className="bg-light"
                            style={{ width: "10%" }}
                            scope="col"
                          ></th>
                          <th
                            className="bg-light"
                            style={{ width: "40%" }}
                            scope="col"
                          >
                            BCA CLAUSE
                          </th>
                          <th
                            className="bg-light"
                            style={{ width: "12%" }}
                            scope="col"
                          >
                            COMPLIES
                          </th>
                          <th
                            className="bg-light"
                            style={{ width: "12%" }}
                            scope="col"
                          >
                            DOES NOT COMPLY
                          </th>
                          <th
                            className="bg-light"
                            style={{ width: "12%" }}
                            scope="col"
                          >
                            DESIGN DETAIL
                          </th>
                          <th
                            className="bg-light"
                            style={{ width: "14%" }}
                            scope="col"
                          >
                            DESIGN CERTIFICATION
                          </th>
                          <th
                            className="bg-light"
                            style={{ width: "14%" }}
                            scope="col"
                          >
                            NOT APPLICABLE
                          </th>
                          <th
                            className="bg-light"
                            style={{ width: "14%" }}
                            scope="col"
                          >
                            PERFORMANCE SOLUTION
                          </th>
                          <th
                            className="bg-light"
                            style={{ width: "14%" }}
                            scope="col"
                          >
                            CAPABLE OF COMPLIANCE
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {obj.data.map((tr) => {
                          {
                            /* if (
                            selected?.section == "Section C – Fire Resistance"
                          ) {
                            

                            if (checkDatawithId(tr.id)) {
                              tr = { ...tr, ["NOT APPLICABLE"]: true };
                            } */
                          }

                          /* else {
                              tr = { id: "", bcaClause: "" };
                            } */
                          {
                            /* } */
                          }

                          return (
                            <>
                              {tr.id && tr.bcaClause ? (
                                <tr>
                                  <th scope="row">{tr.id}</th>
                                  <td>{tr.bcaClause}</td>

                                  <td>
                                    {/* <input type="checkbox" /> */}
                                    <input
                                      type="checkbox"
                                      checked={tr.COMPLIES}
                                      onChange={() =>
                                        handleCheckboxChange(tr.id, "COMPLIES")
                                      }
                                    />
                                  </td>
                                  <td>
                                    {/* <input type="checkbox" /> */}
                                    <input
                                      type="checkbox"
                                      checked={tr["DOES NOT COMPLY"]}
                                      onChange={() =>
                                        handleCheckboxChange(
                                          tr.id,
                                          "DOES NOT COMPLY"
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    {/* <input type="checkbox" /> */}
                                    <input
                                      type="checkbox"
                                      checked={tr["DESIGN DETAIL"]}
                                      onChange={() =>
                                        handleCheckboxChange(
                                          tr.id,
                                          "DESIGN DETAIL"
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    {/* <input type="checkbox" /> */}
                                    <input
                                      type="checkbox"
                                      checked={tr["DESIGN CERTIFICATION"]}
                                      onChange={() =>
                                        handleCheckboxChange(
                                          tr.id,
                                          "DESIGN CERTIFICATION"
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    {/* <input type="checkbox" /> */}
                                    <input
                                      type="checkbox"
                                      checked={tr["NOT APPLICABLE"]}
                                      onChange={() =>
                                        handleCheckboxChange(
                                          tr.id,
                                          "NOT APPLICABLE"
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    {/* <input type="checkbox" /> */}
                                    <input
                                      type="checkbox"
                                      checked={tr["PERFOMANCE SOLUTION"]}
                                      onChange={() =>
                                        handleCheckboxChange(
                                          tr.id,
                                          "PERFOMANCE SOLUTION"
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={tr["CAPABLE OF COMPLIANCE"]}
                                      onChange={() =>
                                        handleCheckboxChange(
                                          tr.id,
                                          "CAPABLE OF COMPLIANCE"
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                );
              })}
            </>
          );
        })}
      </form>
    </>
  );
}

export default FormTwo;
