import React, { memo, useEffect, useRef, useState } from "react";
import FormOne from "../components/FormOne";
import axios from "axios";
import Toastify from "toastify-js";
import Sidebar from "./SideBar";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const RenderStep1 = () => {
  const [selectionType, setSelectionType] = useState("single");
  const clientId = sessionStorage.getItem("clientId");
  const clasData = sessionStorage.getItem("formData");

  const [selectedItems, setSelectedItems] = useState([]);

  const navigate = useNavigate();
  const [multipleFireCompartmentsType, setmultipleFireCompartmentsType] =
    useState("single");
  const [formData, setFormData] = useState({
    client: "",
    projectName: "",
    address: "",
    architect: "",
    projectDescription: "",
    jobNumber: "",
    buildingContain: [],
    classification: [],
    selectedConstruction: "",
    riseInStoreys: "",
    effectiveHeight: "",
    largestFireCompartmentSize: "",
    buildingArea: "",
  });

  useEffect(() => {
    const storedFormData = sessionStorage.getItem("formData");
    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);
      setFormData(parsedFormData);
    }
  }, []);
  const handleNextStep = async () => {
    const selectedConstruction = sessionStorage.getItem("selectedConstruction");
    const selectionType = sessionStorage.getItem("selectionType");
    // const classification = JSON.parse(sessionStorage.getItem("classification"));
    const formDataFromLoc = JSON.parse(sessionStorage.getItem("formData"));

    if (
      (formData.selectedConstruction,
      selectionType,
      formData.classification,
      selectedItems.length,
      formDataFromLoc?.riseInStoreys != "",
      formDataFromLoc?.effectiveHeight != "",
      formData.buildingArea != "",
      formData.client != "",
      formData.projectName != "",
      formData.address != "",
      formData.architect != "",
      formData.projectDescription != "",
      formData.jobNumber != "",
      formData.jobNumber != "",
      formData.selectedConstruction != "",
      formData.riseInStoreys != "",
      formData.effectiveHeight != "",
      formData.largestFireCompartmentSize != "")
    ) {
      const STEP1_URL = `${process.env.REACT_APP_BACKEND_URL}/stepForm/step-1`;

      const payload = {
        clientId: clientId,
        data: [
          {
            fieldname: "Client",
            values: formData.client,
            multiple: false,
            multivalues: [],
          },
          {
            fieldname: "Project Name",
            values: formData.projectName,
            multiple: false,
            multivalues: [],
          },
          {
            fieldname: "Address",
            values: formData.address,
            multiple: false,
            multivalues: [],
          },
          {
            fieldname: "Architect",
            values: formData.architect,
            multiple: false,
            multivalues: [],
          },
          {
            fieldname: "Project Description",
            values: formData.projectDescription,
            multiple: false,
            multivalues: [],
          },
          {
            fieldname: "Job Number",
            values: formData.jobNumber,
            multiple: false,
            multivalues: [],
          },
          {
            fieldname: "Building Classification",
            values: "",
            multiple: true,
            multivalues: [
              {
                2: selectedItems.includes("2"),
              },
              {
                3: selectedItems.includes("3"),
              },
              {
                4: selectedItems.includes("4"),
              },
              {
                5: selectedItems.includes("5"),
              },
              {
                6: selectedItems.includes("6"),
              },
              {
                "7a": selectedItems.includes("7a"),
              },
              {
                "7b": selectedItems.includes("7b"),
              },
              {
                8: selectedItems.includes("8"),
              },
              {
                "9a": selectedItems.includes("9a"),
              },
              {
                "9b": selectedItems.includes("9b"),
              },
              {
                "9c": selectedItems.includes("9c"),
              },
            ],
          },
          {
            fieldname: "Type of Construction",
            values: selectedConstruction,
            multiple: false,
            multivalues: [],
          },
          {
            fieldname: "Rise in Storeys",
            values: formData.riseInStoreys,
            multiple: false,
            multivalues: [],
          },
          {
            fieldname: "Effective Height",
            values: formData.effectiveHeight,
            multiple: false,
            multivalues: [],
          },
          {
            fieldname: "Largest Fire Compartment Size",
            values: formData.largestFireCompartmentSize,
            multiple: false,
            multivalues: [],
          },
          {
            fieldname: "Building Area",
            values: formData.buildingArea,
            multiple: false,
            multivalues: [],
          },
          {
            fieldname: "Does the building contain",
            values: "",
            multiple: true,
            multivalues: [
              {
                stairs: formData.buildingContain.includes("Stairs"),
              },
              {
                "Fire-isolated exit":
                  formData.buildingContain.includes("Fire-isolated exit"),
              },
              {
                Lift: formData.buildingContain.includes("Lift"),
              },
              {
                "Fire-isolated lift":
                  formData.buildingContain.includes("Fire-isolated lift"),
              },
              {
                Atrium: formData.buildingContain.includes("Atrium"),
              },
              {
                School: formData.buildingContain.includes("School"),
              },
              {
                "Childcare Centre":
                  formData.buildingContain.includes("Childcare Centre"),
              },
              {
                "Entertainment Venue": formData.buildingContain.includes(
                  "Entertainment Venue"
                ),
              },
              {
                "Residential Care Building": formData.buildingContain.includes(
                  "Residential Care Building"
                ),
              },
              {
                "Open spectator stands and indoor sports stadiums":
                  formData.buildingContain.includes(
                    "Open spectator stands and indoor sports stadiums"
                  ),
              },
            ],
          },
        ],
      };

      try {
        const response = await axios.post(STEP1_URL, payload);
        if (response.status == 201) {
          navigate("/step2");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      alert("Please fill in the mandatory field.");
    }
  };

  const sendStep1Answers = () => {
    const STEP1_URL = `${process.env.REACT_APP_BACKEND_URL}/stepForm/step-1`;

    const payload = {
      clientId: clientId,
      data: [
        {
          fieldname: formData.client,
          values: "saleel",
          multiple: false,
          multivalues: [],
        },
      ],
    };

    axios
      .post(STEP1_URL, payload)
      .then((response) => {
        console.log("Response:", response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <>
      <div className="content">
        <div className="container multipleForm">
          <div className="row justify-content-center">
            <div className="col">
              <div className="bg-white pb-4 shadow-sm m-lg-5 my-4 border overflow-hidden rounded-3">
                <div>
                  <h3 className="bg-primary p-2">
                    Step 1: Building Information
                  </h3>
                  <FormOne
                    formData={formData}
                    setFormData={setFormData}
                    selectionType={selectionType}
                    setSelectionType={setSelectionType}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    multipleFireCompartmentsType={multipleFireCompartmentsType}
                    setmultipleFireCompartmentsType={
                      setmultipleFireCompartmentsType
                    }
                    sendStep1Answers={sendStep1Answers}
                  />
                </div>
                <div className="mt-3 px-3">
                  {/* <Link>
                    <button className="btn border">Previous</button>
                  </Link> */}

                  <button
                    className="btn bg-primary border ms-2"
                    onClick={handleNextStep}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(RenderStep1);
