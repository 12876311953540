import React, { useEffect, useState } from "react";

function FormOne({
  formData,
  setFormData,
  selectionType,
  setSelectionType,
  selectedItems,
  setSelectedItems,

  multipleFireCompartmentsType,
  setmultipleFireCompartmentsType,
  sendStep1Answers,
}) {
  const buildingClassification = [
    "2",
    "3",
    "4",
    "5",
    "6",
    "7a",
    "7b",
    "8",
    "9a",
    "9b",
    "9c",
  ];
  const [constructions, setConstructions] = useState([
    {
      name: "A",
      checked: false,
    },
    {
      name: "B",
      checked: false,
    },
    {
      name: "C",
      checked: false,
    },
  ]);

  console.log(formData, "formData");
  // const constructions = ["A", "B", "C"];
  // const compartmentSize = ["1835.15", "2335.67"];
  const contains = [
    "Stairs",
    "Fire-isolated exit",
    "Lift",
    "Fire-isolated lift",
    "Atrium",
    "School",
    "Childcare Centre",
    "Entertainment Venue",
    "Residential Care Building",
    "Open spectator stands and indoor sports stadiums",
    "Multiply Fire Compartments",
  ];
  // if(multipleFireCompartmentsType === "multiple") contains.push()

  const [selectionType2, setSelectionType2] = useState("single");
  const [selectedItems2, setSelectedItems2] = useState([]);
  const [selectedConstruction, setSelectedConstruction] = useState("");

  const constructionHandle = (e) => {
    const value = e.target.value;
    console.log(value);
    setSelectedConstruction(value);
  };

  const handleSelectionChange = (event) => {
    setSelectionType(event.target.value);
    setSelectedItems([]);
    setFormData({
      ...formData,
      classification: [],
    });
  };

  useEffect(() => {
    if(selectedItems.length>0){

      sessionStorage.setItem("classification", JSON.stringify(selectedItems));
    }
  }, [selectedItems]);

  useEffect(() => {
    if (selectedConstruction) {
      sessionStorage.setItem("selectedConstruction", selectedConstruction);
    }
  }, [selectedConstruction]);

  useEffect(() => {
    if (formData.selectedConstruction) {
      setSelectedConstruction(formData.selectedConstruction);
    }
  }, [formData.selectedConstruction]);

  useEffect(() => {
    if (selectionType) {
      sessionStorage.setItem("selectionType", selectionType);
    }
  }, [selectionType]);

  useEffect(() => {
    if (formData.selectedConstruction) {
      sessionStorage.setItem("formData", JSON.stringify(formData));
    }
  }, [formData]);

  useEffect(() => {
    sessionStorage.setItem(
      "multipleFireCompartmentsType",
      multipleFireCompartmentsType
    );
  }, [multipleFireCompartmentsType]);

  return (
    <>
      <form className="p-4 pt-2">
        <div className="row mb-4">
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="form6Example1">
                Client
              </label>
              <input
                type="text"
                id="form6Example1"
                className="form-control"
                placeholder="e.g. John Smith Group"
                value={formData.client}
                onChange={(e) => {
                  setFormData({ ...formData, client: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="form6Example2">
                Project Name
              </label>
              <input
                type="text"
                id="form6Example2"
                className="form-control"
                placeholder="e.g. The Parks"
                value={formData.projectName}
                onChange={(e) => {
                  setFormData({ ...formData, projectName: e.target.value });
                }}
              />
            </div>
          </div>
        </div>
        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="form6Example7">
            Address
          </label>
          <textarea
            className="form-control"
            id="form6Example7"
            rows={4}
            // defaultValue={""}
            placeholder="e.g. 123 Blank Street"
            value={formData.address}
            onChange={(e) => {
              setFormData({ ...formData, address: e.target.value });
            }}
          />
        </div>

        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="form6Example3">
            Architect
          </label>
          <input
            type="text"
            id="form6Example3"
            className="form-control"
            placeholder="e.g. Designer R US"
            value={formData.architect}
            onChange={(e) => {
              setFormData({ ...formData, architect: e.target.value });
            }}
          />
        </div>

        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="form6Example7">
            Project Description
          </label>
          <textarea
            className="form-control"
            id="form6Example7"
            rows={4}
            // defaultValue={""}
            placeholder="e.g. Construction of 5 storey apartment building with ground floor for retail"
            value={formData.projectDescription}
            onChange={(e) => {
              setFormData({ ...formData, projectDescription: e.target.value });
            }}
          />
        </div>

        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="form6Example5">
            Job Number
          </label>
          <input
            type="email"
            id="form6Example5"
            className="form-control"
            placeholder="e.g. P221-545"
            value={formData.jobNumber}
            onChange={(e) => {
              setFormData({ ...formData, jobNumber: e.target.value });
            }}
          />
        </div>

        <div className="form-outline mb-4">
          <div className="form-outline">
            <label className="form-label" htmlFor="form6Example6">
              Building Classification
            </label>

            <div className="bg-light p-4 rounded-3">
              <div className="mb-2">
                <label htmlFor="checkbox-single">
                  <input
                    id={`checkbox-single`}
                    className=" me-1"
                    type="radio"
                    value="single"
                    checked={selectionType === "single"}
                    onChange={handleSelectionChange}
                  />
                  Single Select
                </label>
                <label htmlFor="checkbox-multiple" className="ms-3">
                  <input
                    id={`checkbox-multiple`}
                    className=" me-1"
                    type="radio"
                    value="multiple"
                    checked={selectionType === "multiple"}
                    onChange={handleSelectionChange}
                  />
                  Multiple Select
                </label>
              </div>
              <div className="d-flex flex-wrap gap-3 gap-lg-5">
                {buildingClassification.map((obj) => (
                  <div key={obj}>
                    <input
                      id={`checkbox-${obj}`}
                      className="form-check-input me-2"
                      type="checkbox"
                      value={obj}
                      checked={formData.classification.includes(obj)}
                      onChange={(e) => {
                        const value = e.target.value;

                        if (selectionType === "single") {
                          if (formData.classification.includes(value)) {
                            setSelectedItems([]);
                            setFormData({
                              ...formData,
                              classification: [],
                            });
                          } else {
                            setSelectedItems([value]);
                            setFormData({
                              ...formData,
                              classification: [value],
                            });
                          }
                        } else {
                          if (selectedItems.includes(value)) {
                            setSelectedItems(
                              selectedItems.filter((item) => item !== value)
                            );
                            setFormData({
                              ...formData,
                              classification: selectedItems.filter(
                                (item) => item !== value
                              ),
                            });
                          } else {
                            setSelectedItems([...selectedItems, value]);
                            setFormData({
                              ...formData,
                              classification: [...selectedItems, value],
                            });
                          }
                        }
                      }}
                    />
                    <label htmlFor={`checkbox-${obj}`}>{obj}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="form-outline mb-4">
          <div className="form-outline">
            <label className="form-label" htmlFor="form6Example6">
              Type of Construction
            </label>

            <div>
              <div className="d-flex gap-5">
                {constructions.map((obj) => (
                  <div key={obj.name}>
                    <input
                      id={`checkbox-${obj.name}`}
                      className="form-check-input me-2"
                      type="checkbox"
                      value={obj.name}
                      checked={selectedConstruction == obj.name}
                      // onChange={constructionHandle}
                      onChange={(e) => {
                        constructionHandle(e);
                        setFormData({
                          ...formData,
                          selectedConstruction: obj.name,
                        });
                      }}
                    />
                    <label htmlFor={`checkbox-${obj.name}`}>{obj.name}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="form6Example1">
                Rise in Storeys
              </label>
              <input
                type="text"
                id="form6Example1"
                className="form-control"
                placeholder="e.g. 5"
                value={formData.riseInStoreys}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (
                    /^\d*$/.test(inputValue) &&
                    Number(inputValue) >= 0 &&
                    Number(inputValue) <= 100
                  ) {
                    setFormData({ ...formData, riseInStoreys: inputValue });
                  }
                }}
              />
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="form6Example2">
                Effective Height
              </label>
              <input
                type="text"
                id="form6Example2"
                className="form-control"
                placeholder="e.g. 35.35 m"
                value={formData.effectiveHeight}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (
                    /^\d*\.?\d{0,2}$/.test(inputValue) &&
                    Number(inputValue) >= 0 &&
                    Number(inputValue) <= 1000
                  ) {
                    setFormData({
                      ...formData,
                      effectiveHeight: e.target.value,
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="form-outline mb-4">
          <div className="form-outline">
            <label className="form-label" htmlFor="form6Example6">
              Largest Fire Compartment Size
            </label>

            <div className="rounded-3">
              {/* <div className="mb-2">
                <label>
                  <input
                   className=" me-1"
                    type="radio"
                    value="single"
                    checked={multipleFireCompartmentsType === "single"}
                    onChange={handleSelectionChange2}
                  />
                  Single Select
                </label>
                <label className="ms-3">
                  <input
                   className=" me-1"
                    type="radio"
                    value="multiple"
                    checked={multipleFireCompartmentsType === "multiple"}
                    onChange={handleSelectionChange2}
                  />
                  Multiple Selects
                </label>
              </div> */}
              {/* <div className="d-flex gap-5">
                {compartmentSize.map((obj) => (
                  <div key={obj}>
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value={obj}
                      checked={selectedItems2.includes(obj)}
                      onChange={handleCheckboxChange2}
                    />
                    <label>{obj}</label>
                  </div>
                ))}
              </div> */}
              <input
                type="text"
                id="form6Example2"
                className="form-control"
                placeholder="e.g. 1535.35 m2"
                value={formData.largestFireCompartmentSize}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (
                    /^\d*\.?\d{0,2}$/.test(inputValue) &&
                    Number(inputValue) >= 0 &&
                    Number(inputValue) <= 25000
                  ) {
                    setFormData({
                      ...formData,
                      largestFireCompartmentSize: inputValue,
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="form6Example1">
                Building Area
              </label>
              <input
                type="text"
                id="form6Example1"
                className="form-control"
                placeholder="e.g. 1535.35 m2"
                value={formData.buildingArea}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (
                    /^\d*\.?\d{0,2}$/.test(inputValue) &&
                    Number(inputValue) >= 0 &&
                    Number(inputValue) <= 25000
                  ) {
                    setFormData({ ...formData, buildingArea: inputValue });
                  }
                }}
              />
            </div>
          </div>
          <div className="col">
            {/* <div className="form-outline">
              <label className="form-label" htmlFor="form6Example2">
                Project Name
              </label>
              <input type="text" id="form6Example2" className="form-control" />
            </div> */}
          </div>
        </div>
        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="form6Example6">
            Does the building contain?
          </label>

          <div className="row">
            {contains.map((obj) => (
              <div className="col-md-4" key={obj}>
                <div className="d-flex">
                  <input
                    id={`checkbox-${obj}`}
                    className="form-check-input me-2"
                    type="checkbox"
                    value={obj}
                    // onChange={handleBuildingContainCheckboxChange}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      setFormData((prev) => {
                        if (prev.buildingContain.includes(selectedValue)) {
                          return {
                            ...prev,
                            buildingContain: prev.buildingContain.filter(
                              (item) => item !== selectedValue
                            ),
                          };
                        }

                        return {
                          ...prev,
                          buildingContain: [
                            ...prev.buildingContain,
                            selectedValue,
                          ],
                        };
                      });
                    }}
                    checked={formData.buildingContain.includes(obj)}
                  />
                  <label htmlFor={`checkbox-${obj}`}>{obj}</label>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* <button type="submit" className="btn btn-primary btn-block mb-4">
          Submit
        </button> */}
      </form>
    </>
  );
}

export default FormOne;
