// DocumentList.js
import React, { memo, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

function DocumentList({ userId, setdocumentCount }) {
  const [documents, setDocuments] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isLoading, setIsLoading] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const GET_DOC_URL = `${process.env.REACT_APP_BACKEND_URL}/generate-doc/getAllDoc`;
  const DOWNLOAD_DOC = `${process.env.REACT_APP_BACKEND_URL}/generate-doc/download-doc`;
  const DOWNLOAD_PDF = `${process.env.REACT_APP_BACKEND_URL}/generate-doc/download-pdf`;

  // const downloadDocument = async (document) => {
  //   try {
  //     // Find the index of the document in the array
  //     const documentIndex = documents.findIndex((doc) => doc.id === document.id);

  //     // Create a copy of the documents array with updated loading state for the specific document
  //     const updatedDocuments = [...documents];
  //     updatedDocuments[documentIndex] = { ...updatedDocuments[documentIndex], isLoading: true };
  //     setDocuments(updatedDocuments);

  //     // Perform the download here, and when done, set isLoading to false for the specific document
  //     const response = await axios.get(`${DOWNLOAD_DOC}/${userId}/${document.document_name}`, {
  //       responseType: 'blob',
  //     });

  //     if (response.status == 404) {
  //       setErrorMessage("Document not found");
  //     }
  //     const blobUrl = URL.createObjectURL(response.data);
  //     const link = document.createElement('a');
  //     link.href = blobUrl;
  //     link.download = document.document_name;
  //     link.click();

  //     URL.revokeObjectURL(blobUrl);
  //   } catch (error) {
  //     console.error('Error downloading document:', error);
  //   } finally {
  //     // Set isLoading to false for the specific document after the download is complete or an error occurs
  //     const documentIndex = documents.findIndex((doc) => doc.id === document.id);
  //     const updatedDocuments = [...documents];
  //     updatedDocuments[documentIndex] = { ...updatedDocuments[documentIndex], isLoading: false };
  //     setDocuments(updatedDocuments);
  //   }
  // };

  const downloadDocument = async (document_name,document_client,jobNumber) => {
    try {
      // setIsLoading(true);
      setIsLoading({ ...isLoading, [document_name]: true });

      const response = await axios.get(
        `${DOWNLOAD_DOC}/${document_client}/${document_name}`,
        {
          responseType: "blob",
        }
      );

      if (response.status === 404) {
        setErrorMessage("Document not found");
        return;
      }

      const blobUrl = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = jobNumber ?? document_name;
      link.click();

      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading document:", error);
    } finally {
      // setIsLoading(false);
      setIsLoading({ ...isLoading, [document_name]: false });
    }
  };

  async function downloadPDF(documentName) {
    try {
      const serverUrl = "YOUR_SERVER_URL";

      const response = await axios.get(`${DOWNLOAD_PDF}/${documentName}`, {
        responseType: "arraybuffer",
      });

      const blob = new Blob([response.data], { type: "application/pdf" });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "converted_document.pdf";
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading document:", error);
    }
  }

  const fetchDocuments = async () => {
    try {
      const response = await axios.get(GET_DOC_URL, {
        params: {
          clientId: userId,
          searchKey: searchKey,
          fromDate: fromDate,
          toDate: toDate,
          skip: currentPage,
        },
      });
      setDocuments(response.data.documents);

      const totalpages = Math.ceil(response.data.count / 10);
      setTotalCount(totalpages);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const refecthDocuments = async () => {
    try {
      const response = await axios.get(GET_DOC_URL, {
        params: {
          clientId: userId,
          searchKey: '',
          fromDate: '',
          toDate: '',
          skip: 1,
        },
      });
      setDocuments(response.data.documents);

      const totalpages = Math.ceil(response.data.count / 10);
      setTotalCount(totalpages);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [currentPage]);

  return (
    <div
      className="step3 @container p-3"
      style={{ width: "100%", justifyContent: "center" }}
    >
      <div
        className="input-group mb-3 mt-4"
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        <div
          className="d-flex justify-content-end me-2"
          style={{ width: "30%" }}
        >
          <input
            type="text"
            placeholder="Search documents..."
            value={searchKey}
            onChange={(e) => setSearchKey(e.target.value)}
            className="form-control"
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            className="form-control me-2"
          />
          <input
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            className="form-control"
          />
          <button
            className="btn btn-light ms-1"
            onClick={() => {
              setSearchKey("");
              setFromDate("");
              setToDate("");
              refecthDocuments()
            }}
          >
            Reset
          </button>
          <button className="btn bg-primary ms-1" onClick={fetchDocuments}>
            Search
          </button>
        </div>
      </div>
      {/* <hr></hr> */}

      {documents.length === 0 ? (
        <p>No documents found.</p>
      ) : (
        <>
          <ul
            className="step3-documentList p-0"
            style={{
              "max-height": "77vh",
              "overflow-y": "auto",
            }}
          >
            <li className="tableHead bg-primary  p-2">
              <div className="d-flex justify-content-between align-items-center ">
                <div className="col-2 ">
                  <strong>Sr. No</strong>
                </div>
                <div className="col-2 text-center@">
                  <strong>Document ID</strong>
                </div>
                <div className="col-3">
                  <strong>Project Name</strong>
                </div>
                <div className="col-3 text-center@">
                  <strong>Created On </strong>
                </div>
                <div className="col-2 text-end">
                  <strong>Document</strong>
                </div>
              </div>
            </li>
            {documents.map((document, i) => (
              <li key={document.id} className="list-group-item mb-3 py-2">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="col-2">
                    <span className="fw-bold ps-2"></span> {i + 1}
                  </div>
                  <div className="col-2">
                    <span className="fw-bold"></span> {document.docId}
                  </div>
                  <div className="col-3">
                    <strong className="ps-2">{document.projectName} </strong>
                  </div>
                  <div className="col-3">
                    <span className="fw-bold"></span>{" "}
                    {new Date(document.createdAt).toLocaleString()}
                  </div>
                  <div className="col-2 text-end">
                    <button
                      className="btn btn-sm btn-light  "
                      onClick={() => {
                        downloadDocument(document.document_name,document.clientId,document.jobNumber);
                      }}
                    >
                      Download Docx
                      {isLoading[document.document_name] ? (
                        <span
                          className="spinner-border spinner-border-sm ms-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        ""
                      )}
                    </button>
                    {/* <button className="btn btn-sm btn-warning me-2" onClick={() => { downloadPDF(document.document_name) }}>Download Pdf</button> */}
                  </div>
                </div>
              </li>
            ))}
            {errorMessage && (
              <div className="alert alert-warning">{errorMessage}</div>
            )}
          </ul>
        </>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        <button
          className="btn bg-primary me-2"
          onClick={handlePreviousPage}
          disabled={currentPage === 1} // Disable "Previous" button on the first page
        >
          Previous
        </button>
        <p className="m-2">Page :{currentPage}</p>
        {currentPage < totalCount && (
          <button className="btn bg-primary" onClick={handleNextPage}>
            Next
          </button>
        )}
      </div>
    </div>
  );
}

export default memo(DocumentList);
