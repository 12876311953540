import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";

function UserForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const API_URL = `${process.env.REACT_APP_BACKEND_URL}/admin/create-user`;

  const handleSubmit = async (event) => {
    event.preventDefault();
  if (!validatePassword(password)) {
    toast.error("Invalid password. Password must be alphanumeric and at least 6 characters long.");
    return;
  }
  const newUser = {
    email,
    password,
    name,
  };
  try {
    const response = await axios.post(API_URL, newUser);
    setSuccessMessage("User added successfully");
  } catch (error) {
    console.error("Error adding user:", error);
  }
  setEmail("");
  setPassword("");
  setName("");
  };

  const validatePassword = (password) => {
    const re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{6,}$/;
    return re.test(password);
  };
  
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <div className="container@">
    <div className="row Admin-addUser ">
      <div className="col-lg-4">
      <div className="">
        <h2>Add User</h2>
        {successMessage && (
          <div className="alert alert-success">{successMessage}</div>
        )}
        <form className="Admin-addUserBox" onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              type="test"
              className="form-control"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
          </div>
          <button type="submit" className="btn bg-primary">
            Add User
          </button>
        </form>
      </div>
      </div>
    </div>
    </div>
  );
}

export default UserForm;
