import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const handleLogout = () => {
  sessionStorage.clear();
  window.location.href = '/';
};

const TopNavbar = ({showTab = true}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const [tab, setTab] = useState(showTab ? [
    { label: "Form", href: 'step1' },
    { label: "Document", href: 'Documents' },
  ] : []);

  const tabHandle = (index) => {
    setActiveIndex(index);
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-sticky MenuTopbar">
      <div className="container">
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto">
            {tab.map((item, index) => (
              <li
                key={index}
                onClick={() => tabHandle(index)}
                className={`${activeIndex === index ? 'active' : ''} nav-item`}
              >
                <Link className="nav-link" to={`/${item.href}`}>
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <button className="btn btn-danger @bg-primary" onClick={handleLogout}>Logout</button>
      </div>
    </nav>
  );
};

export default TopNavbar;
